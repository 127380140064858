import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container } from "react-bootstrap";
import { SliceZone } from "@prismicio/react";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import components from "../components/slices/Index";
import { constructActiveDoc } from "../utilities";

const ProjectPage = ({ data }) => {
  if (!data) return null;
  const { prismicSettings: settings, project } = data;

  return (
    <>
      <Seo page={project} />
      <Layout settings={settings} activeDocMeta={constructActiveDoc(project)}>
        <Container className="project-page my-5">
          <h1 className="my-5">{project.data.title}</h1>
          <div className="d-flex justify-content-center gap-3">
            {project.data.project_main_image.map((image, i) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={i}>
                  <GatsbyImage
                    image={image.main_image.gatsbyImageData}
                    alt={image.main_image.alt}
                  />
                </div>
              );
            })}
          </div>
          <SliceZone slices={project.data.body} components={components} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query projectQuery($lang: String, $id: String) {
    prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    project: prismicProject(lang: { eq: $lang }, id: { eq: $id }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      uid
      tags
      data {
        title
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        project_main_image {
          main_image {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        body {
          ... on PrismicProjectDataBodyDownloadFiles {
            id
            slice_type
            slice_label
            items {
              label
              link_to_file {
                url
                type
                target
                id
              }
            }
          }
          ... on PrismicProjectDataBodyRichText {
            id
            slice_type
            slice_label
            primary {
              text_description {
                richText
              }
            }
          }
          ... on PrismicProjectDataBodyGallery {
            id
            slice_type
            slice_label
            primary {
              button_label
            }
            items {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicProjectDataBodyGallery1 {
            id
            slice_type
            slice_label
            primary {
              button_label
            }
            items {
              image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicProjectDataBodySingleImage {
            id
            slice_type
            slice_label
            primary {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(ProjectPage);
